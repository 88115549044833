import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import "./footer.css";

const Footer = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    const checkscrolled = () => {
      setIsScrolled(document.body.scrollTop > 100);
    }
    checkscrolled();
    document.body.addEventListener('scroll', checkscrolled);
    return () => {
      document.body.removeEventListener('scroll', checkscrolled);
    }
  }, [])
  return <footer>
    <div className="seperator"></div>
    <div className="slogan">
      <div className="sloganLeft">
        Lets Work Together<br/>
        Send me a message
      </div>
      <div className="sloganRight"></div>
    </div>
    <div className="contacts">
      <div className="col">
        <div className="colHeader">
          Find me
        </div>
        <div className="colItem">
          <a href="tel:+44 (0)7704992953">+44 (0)7704992953</a>
        </div>
        {/* <div className="colItem">
          <a href="https://wa.me/85291239219/?text=Nice to meet you." target="_blank">+852 91239219</a> <span>(Whatsapp)</span>
        </div> */}
        <div className="colItem">
          <a href="mailto:panghojing@gmail.com">panghojing@gmail.com</a>
        </div>
      </div>
      <div className="col">
        <div className="colHeader">
          Follow me
        </div>
        <div className="subCol">
          <div className="colItem">
            <a href="https://www.instagram.com/siujing/" target="_blank">Instagram</a>
          </div>
          <div className="colItem">
            <a href="https://www.instagram.com/11blab/" target="_blank">11blab</a>
          </div>
          <div className="colItem">
            <a href="https://www.instagram.com/dune.labo/" target="_blank">Dune Labo</a>
          </div>
        </div>
        <div className="subCol">
          <div className="colItem">
            <a href="https://www.behance.net/Hojing" target="_blank">Behance</a>
          </div>
          <div className="colItem">
            <a href="https://hk.linkedin.com/in/hojing" target="_blank">LinkedIn</a>
          </div>
        </div>
      </div>
    </div>
    <div className="footer">
      <div className="me">HOJING</div>
      <div className="copyright">HOJING &copy; 2022</div>
      <div className="tnc">All Right Reserved</div>
    </div>
    <div className="backToTopWrapper">
      <div className="backToTopPlaceholder">
        <img src="./media/Back_To_Top.svg" alt="" />
      </div>
      <Link id="backToTop" 
        className={isScrolled? '': 'hide'} 
        to="#" 
        onClick={(e) => {
          e.preventDefault();
          document.body.scrollTop = 0;
          return false;
        }}
      >
        <img src="./media/Back_To_Top.svg" alt="" />
      </Link>
    </div>
  </footer>;
}

export default Footer;