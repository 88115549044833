import React, { useContext, useEffect } from "react";
import Header from "./container/header";
import Landing from "./pages/landing";
import LoadingScreen from "./pages/loading";
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import routes from "./global/routes";
import About from "./pages/about";
import Footer from "./container/footer";
import Project from "./pages/project";


const App = () => {
  const {pathname: currentPage} = useLocation();
  useEffect(() => {
    document.body.style.scrollBehavior = 'auto';
    document.body.scrollTop = 0;
    document.body.style.scrollBehavior = '';
  }, [currentPage])
  return <div id="app">
    <LoadingScreen/>
    <Header />
    <Routes>
      <Route path={routes.landing} element={<Landing/>} />
      <Route path={routes.about} element={<About/>} />
      <Route path={routes.project} element={<Project/>} />
      <Route path="*" element={<Navigate to={routes.landing} />} />
    </Routes>
    <Footer />
  </div>;
}

export default App;