import React from "react";
import ProjectList from "../container/projectList";
import SelfIntro from "../container/selfIntro";

const Landing = () => {
  return <div id="landing">
    <SelfIntro />
    {/* {images.map(img => <img key={img} src={img} />)} */}
    <ProjectList />
  </div>

}

export default Landing;