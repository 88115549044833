import React, { useContext, useEffect, useState } from "react";
import { Link, Routes, useLocation, useParams } from "react-router-dom";
import preloadContext from "../contexts/preload/preloadContext";
import jsonContext from "../contexts/json/jsonContext";
import routes from "../global/routes";

import "./project.css";

const Project = () => {
  const {project: projectSlug} = useParams();
  const {projects} = useContext(jsonContext);
  const {start, startPreload} = useContext(preloadContext);
  const [currentProject, setCurrentProject] = useState(null);
  useEffect(() => {
    let projectFound = null;
    if (projects && projectSlug) {
      projectFound = projects.find(el => el['slug'] === projectSlug);
    }
    if (projectFound) {
      setCurrentProject(projectFound);
      let projectImages = projectFound['contents']?.map((el, idx) => {
        switch (el['type']) {
          case 'banner':
          case 'photo':
            return el['src'];
          case 'text':
          case 'vimeo':
          default:
            return null;
        }
      }).filter(el => el);
      if (projectImages)
        projectImages.push(projectFound['thumb']);
      // console.log(projectFound['name'])
      // get all images from json and preload it
      startPreload(projectImages);
    }
  }, [projects, projectSlug])

  return <div id="project" className={start? 'loading': ''}>
    <Link to={routes.landing} className="backToWork">
      <img src="./media/Back.svg" />
    </Link>

    {currentProject && <>
      <div className="projectName" dangerouslySetInnerHTML={{
        __html: currentProject.name
      }} />
      <div className="projectCategory">{currentProject.categoryDisplay}</div>
        {currentProject.projectCoverVimeo ?
          <div className="projectCoverVimeo">
            <iframe src={`https://player.vimeo.com/video/${currentProject.projectCoverVimeo}?background=1&autoplay=1&loop=1&autopause=0`} frameBorder="0" allow="autoplay" />
          </div>:
          <div className="projectThumb">
            <img src={currentProject.projectCoverImage} alt="" />
          </div>
        }
      
      <div className="brief">
        <div className="shortInfos">
          {currentProject['role'] && <div className="infoItem">
            <div className="header">Role:</div>
            <div className="content" dangerouslySetInnerHTML={{
              __html: currentProject['role']
            }}/>
          </div>}
          {currentProject['client'] && <div className="infoItem">
            <div className="header">Client:</div>
            <div className="content" dangerouslySetInnerHTML={{
              __html: currentProject['client']
            }}/>
          </div>}
          {currentProject['link'] && <div className="infoItem">
            <div className="header">Links:</div>
            <div className="content" dangerouslySetInnerHTML={{
              __html: currentProject['link']
            }}/>
          </div>}
          {currentProject['awards'] && <div className="infoItem">
            <div className="header">Awards:</div>
            <div className="content" dangerouslySetInnerHTML={{
              __html: currentProject['awards']
            }}/>
          </div>}
          {currentProject['credits'] && <div className="infoItem">
            <div className="header">Credits:</div>
            <div className="content" dangerouslySetInnerHTML={{
              __html: currentProject['credits']
            }}/>
          </div>}
        </div>
        <div className="shortBrief" dangerouslySetInnerHTML={{
          __html: currentProject['brief']
        }} />
      </div>
      {currentProject['contents']?.map((el, idx) => {
        switch (el['type']) {
          case 'banner':
            return <div className="content banner" key={idx}>
              <img src={el['src']} alt="" />
            </div>;
          case 'photo':
            return <div className="content photo" key={idx}>
              <img src={el['src']} alt="" />
            </div>;
          case 'text':
            return <div className="content text" key={idx}>
                <div dangerouslySetInnerHTML={{
                  __html: el['content']
                }} />
            </div>;
          case 'vimeo':
            return <div className="content vimeo" key={idx}>
              <div className="vimeoWrapper" style={el['ratio']? {paddingTop: `${el['ratio']}%`}: {}}>
                <iframe src={`https://player.vimeo.com/video/${el['id']}?background=1&autoplay=1&loop=1&autopause=0`} frameBorder="0" allow="autoplay" />
              </div>
            </div>;
          case 'vimeoBanner':
            return <div className="content vimeo fullwidth" key={idx}>
              <div className="vimeoWrapper" style={el['ratio']? {paddingTop: `${el['ratio']}%`}: {}}>
                <iframe src={`https://player.vimeo.com/video/${el['id']}?background=1&autoplay=1&loop=1&autopause=0`} frameBorder="0" allow="autoplay" />
              </div>
            </div>;
        }
      })}
    </>}
    <Link to={routes.landing} className="backToWork">
      <img src="./media/Back.svg" /><br/>
      Back To Work
    </Link>
  </div>
}

export default Project;