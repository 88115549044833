import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import preloadContext from "../contexts/preload/preloadContext";
import routes from "../global/routes";
import "./header.css"; 

const Header = () => {
  const {pathname: currentPage} = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const {firstLoaded} = useContext(preloadContext);
  return <header>
    <div className="me">HOJING</div>
    <div className="post">Design and Art Direction</div>
    <div className={`mainNav${firstLoaded? '': ' hide'}`}>
      <Link to={routes.landing} className={currentPage === routes.landing? 'active': ''} onClick={()=>false}>WORKS</Link>
      <Link to={routes.about} className={currentPage === routes.about? 'active': ''} onClick={()=>false}>ABOUT</Link>
    </div>
    <div className={`mobile${menuOpen? ' opened': ''}`} onClick={()=>setMenuOpen(p=>!p)}>
      <span className="line"></span>
      <span className="line"></span>
    </div>
    <div className={`mobileMenu${menuOpen? '': ' hide'}`} onClick={() => setMenuOpen(false)}>
      <div className="menuBackground"></div>
      <div className="menuItems">
        <Link to={routes.landing} className={currentPage === routes.landing? 'active': ''} onClick={()=>false}>WORKS</Link>
        <Link to={routes.about} className={currentPage === routes.about? 'active': ''} onClick={()=>false}>ABOUT</Link>
      </div>
      <div className="contactInfo">
        <div className="col">
          <div className="colHeader">Find me</div>
          <div className="colItem">
            <a href="tel:+44 (0)7704992953">+44 (0)7704992953</a>
          </div>
          <div className="colItem">
            <a href="mailto:panghojing@gmail.com">panghojing@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
  </header>
}

export default Header;