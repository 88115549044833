import React, { useContext } from "react";
import preloadContext from "../contexts/preload/preloadContext";

import "./selfIntro.css";

const SelfIntro = () => {
  const {start} = useContext(preloadContext);
  return <div className={`selfIntro${start? ' loading': ''}`}>
    <div className="text">
      <div className="textLeft">
        Availability<br/>
        I’m open for new collaborations<br/>
        and job opportunities. <a href="mailto:panghojing@gmail.com" tagget="_blank">Get in touch</a>
      </div>
      <div className="textRight">
      I’m a <u>London</u> based Designer and Digital Art Director focusing on digital brand-building and interactive designs with a human-centered, experience-first and holistic approach. Crossing both <u>Online and Offline</u>.
      </div>
    </div>
    <div className="seperator"></div>
  </div>;
}

export default SelfIntro;