import React, { useReducer, useCallback, useEffect, useState } from 'react';

import JsonContext from './jsonContext';
import JsonReducer, { SET_ABOUT, SET_PROJECTS } from './jsonReducer';

import {aboutDataUrl, projectsDataUrl} from '../../global/config';
import axios from 'axios';

const initialState = {
  projects: [],
  about: []
};

const JsonState = ({ children }) => {
  const [state, dispatch] = useReducer(JsonReducer, initialState);
  useEffect(() => {
    (async () => {
      const projectsData = await axios.get(projectsDataUrl);
      dispatch({
        type: SET_PROJECTS,
        payload: {
          projects: projectsData.data
        }
      });
    })();
    (async () => {
      const aboutData = await axios.get(aboutDataUrl);
      dispatch({
        type: SET_ABOUT,
        payload: {
          about: aboutData.data
        }
      });
    })();
  }, []);
  
  return <JsonContext.Provider
    value={{
      ...state
    }}
  >
    {children}
  </JsonContext.Provider>
}

export default JsonState;