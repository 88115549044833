import React, { useContext, useEffect, useState } from "react";
import preloadContext from "../contexts/preload/preloadContext";

import './loading.css';

const LoadingScreen = () => {
  const {start, loadedPercent, startPreload} = useContext(preloadContext);
  const [show, setShow] = useState(true);

  useEffect(() => {
    if (start) {
      setShow(true);
    } else {
      setTimeout(() => {
        setShow(false)
      }, 500)
    }
  }, [start])
  return <div id="loading" className={`${show? '': ' hide'}`}>
    <div className="loadingpercent" style={{ 
      bottom: `${loadedPercent}%`,
      transform: `translateY(${loadedPercent}%) translateY(${loadedPercent * 0.1}rem)`,
    }}>
      <span className="digit">{loadedPercent}</span><span className="sign">%</span>
    </div>
  </div>
}

export default LoadingScreen;