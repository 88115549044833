export const SET_LOADED_PERCENT = 'SET_LOADED_PERCENT';
export const ADD_IMAGE_PRELOAD = 'ADD_IMAGE_PRELOAD';
export const START_PRELOAD = 'START_PRELOAD';
export const FIRST_LOAD_ENDED = 'FIRST_LOAD_ENDED';


export default (state, action) => {
  switch (action.type) {
    case ADD_IMAGE_PRELOAD:
      return {
        ...state,
        images: [...state.images, ...action.payload.images]
      };
    case SET_LOADED_PERCENT:
      return {
        ...state,
        loadedPercent: action.payload.loadedPercent
      };
    case START_PRELOAD:
      return {
        ...state,
        start: action.payload.start
      };
    case FIRST_LOAD_ENDED:
      return {
        ...state,
        firstLoaded: true
      };
    default:
      return state;
  }
};