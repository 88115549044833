import React, { useCallback, useContext, useEffect, useRef } from "react";
import { Link, useLocation, generatePath, createSearchParams, useSearchParams } from "react-router-dom";
import preloadContext from "../contexts/preload/preloadContext";
import jsonContext from "../contexts/json/jsonContext";
import routes from "../global/routes";

import "./projectList.css";

const ProjectList = () => {
  const {projects} = useContext(jsonContext);
  const {start, startPreload} = useContext(preloadContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const filter = searchParams.get('filter');
  const sortOrder = searchParams.get('sort') || 'asc';
  const nextSortOrder = sortOrder === 'asc'? 'desc': 'asc';
  const currentList = useRef(null);
  const filterElment = useRef(null);
  const layoutUpdateTimer = useRef(null);
  const isAnimated = useRef(false);
  const setCurrentList = (ref) => currentList.current = ref;
  const setFilterElment = (ref) => filterElment.current = ref;
  const relocateItems = useCallback(() => {
    const {width: windowWidth} = currentList.current.getBoundingClientRect();
    const isMobile = windowWidth < 768;
    const rem = windowWidth / (isMobile? 375: 1920);
    const allItems = [...currentList.current.querySelectorAll('.projectItem')];
    // const sortedProjects = (sortOrder === "desc"? [...projects].reverse(): projects);
    const sortedProjects = projects;
    const isSkipAnimationDelay = !isAnimated.current;
    currentList.current.style.opacity = isSkipAnimationDelay? 1: 0;

    if (isMobile) {
      clearTimeout(layoutUpdateTimer);
      currentList.current.style.opacity = 0;
      layoutUpdateTimer.current = setTimeout(() => {
        allItems.forEach((el, idx) => {
          if (!filter || (filter && sortedProjects[idx]['category'].indexOf(filter) !== -1)) {
            const itemWidth = windowWidth - 30 * rem;
            el.style.width = `${windowWidth - 30 * rem}px`;
            el.style.left = '';
            el.style.top = '';
            el.style.display = '';
            el.classList.remove('small');
            el.querySelector('.projectThumb').style.height = `${itemWidth / 16 * 9}px`;
          } else {
            el.style.display = 'none';
          }
        })
        currentList.current.style.opacity = 1;
        // if (!isSkipAnimationDelay) {
        //   document.body.scrollTop = filterElment.current.offsetTop - 60 * rem;
        // }

      }, isSkipAnimationDelay? 100: 400);
      
      currentList.current.style.height = '';
    } else {
      let currentItemIdx = 0;
      let lastHeight = 0;
      let currentHeight = 0;
      allItems.forEach((el, idx) => {
        if (filter) {
          if (sortedProjects[idx]['category'].indexOf(filter) !== -1) {
            el.style.width = `${windowWidth - 100 * rem}px`;
            el.querySelector('.projectThumb').style.height = `${windowWidth / 16 * 9}px`;
            el.style.left = `${50 * rem}px`;
            el.style.display = '';
            el.classList.remove('small');
          } else {
            el.style.display = 'none';
          }
        } else {
          el.style.display = '';
          if (sortedProjects[idx]['featured']) {
            el.style.width = `${windowWidth}px`;
            el.style.left = ``;
            el.querySelector('.projectThumb').style.height = `${windowWidth / 16 * 9}px`;
          } else {
            let itemWidth = windowWidth;
            switch (currentItemIdx % 7) {
              case 0:
                el.style.width = `${windowWidth * 0.7 - 100 * rem}px`;
                el.style.left = `${50 * rem}px`;
                el.querySelector('.projectThumb').style.height = el.style.width;
                break;
              case 1:
                el.style.width = `${windowWidth * 0.3 - 50 * rem}px`;
                el.style.left = `${windowWidth * 0.7}px`;
                el.querySelector('.projectThumb').style.height = el.style.width;
                el.classList.add('small');
                break;
              case 2:
                itemWidth = windowWidth - 100 * rem;
                el.style.width = `${itemWidth}px`;
                el.style.left = `${50 * rem}px`;
                el.querySelector('.projectThumb').style.height = `${itemWidth / 16 * 9}px`;
                break;
              case 3:
                el.style.width = `${windowWidth * 0.4 - 50 * rem}px`;
                el.style.left = `${50 * rem}px`;
                el.querySelector('.projectThumb').style.height = el.style.width;
                el.classList.add('small');
                break;
              case 4:
                el.style.width = `${windowWidth * 0.4 - 50 * rem}px`;
                el.style.left = `${50 * rem}px`;
                el.querySelector('.projectThumb').style.height = el.style.width;
                el.classList.add('small');
                break;
              case 5:
                el.style.width = `${windowWidth * 0.6 - 100 * rem}px`;
                el.style.left = `${windowWidth * 0.4 + 50 * rem}px`;
                el.querySelector('.projectThumb').style.height = el.style.width;
                break;
              case 6:
                itemWidth = windowWidth - 100 * rem;
                el.style.width = `${itemWidth}px`;
                el.style.left = `${50 * rem}px`;
                el.querySelector('.projectThumb').style.height = `${itemWidth / 16 * 9}px`;
                break;
            }
            currentItemIdx++;
          }
        }
      });
      clearTimeout(layoutUpdateTimer);
      layoutUpdateTimer.current = setTimeout(() => {
        currentItemIdx = 0;
        allItems.forEach((el, idx) => {
          const itemHeight = el.getBoundingClientRect().height;
          if (filter) {
            if (sortedProjects[idx]['category'].indexOf(filter) !== -1) {
              el.style.top = `${currentHeight}px`;
              currentHeight += itemHeight + 50 * rem;
            }
          } else {
            if (sortedProjects[idx]['featured']) {
              el.style.top = `${currentHeight}px`;
              el.style.left = ``;
              lastHeight = currentHeight;
              currentHeight += itemHeight + 50 * rem;
              lastHeight = currentHeight;
            } else {
              switch (currentItemIdx % 7) {
                case 0:
                  el.style.top = `${currentHeight}px`;
                  lastHeight = currentHeight;
                  currentHeight += itemHeight + 50 * rem;
                  break;
                case 1:
                  {
                    let newTop = currentHeight;
                    if (currentHeight === lastHeight) {
                      newTop = currentHeight;
                      currentHeight += itemHeight + 50 * rem;
                    } else {
                      newTop = (currentHeight - lastHeight - itemHeight) / 2 + lastHeight;
                    }
                    el.style.top = `${newTop}px`;
                  }
                  break;
                case 2:
                  el.style.top = `${currentHeight}px`;
                  lastHeight = currentHeight;
                  currentHeight += itemHeight + 50 * rem;
                  break;
                case 3:
                  el.style.top = `${currentHeight}px`;
                  lastHeight = currentHeight;
                  currentHeight += itemHeight + 50 * rem;
                  break;
                case 4:
                  el.style.top = `${currentHeight}px`;
                  currentHeight += itemHeight + 50 * rem;
                  break;
                case 5:
                  {
                    let newTop = currentHeight;
                    if (currentHeight === lastHeight) {
                      newTop = currentHeight;
                      currentHeight += itemHeight + 50 * rem;
                    } else {
                      newTop = (currentHeight - lastHeight - itemHeight) / 2 + lastHeight;
                    }
                    el.style.top = `${newTop}px`;
                  }
                  break;
                case 6:
                  el.style.top = `${currentHeight}px`;
                  lastHeight = currentHeight;
                  currentHeight += itemHeight + 50 * rem;
                  break;
              }
              currentItemIdx++;
            }
          }
        })
        currentList.current.style.opacity = 1;
        currentList.current.style.height = `${currentHeight + 100 * rem}px`;

        if (!isSkipAnimationDelay) {
          document.body.scrollTop = filterElment.current.offsetTop;
        }

      }, isSkipAnimationDelay? 150: 800)
    }
    isAnimated.current = false;
  }, [projects, filter, sortOrder]);
  useEffect(() => {
    relocateItems();
    window.addEventListener('resize', relocateItems);
    return () => {
      window.removeEventListener('resize', relocateItems);
    }
  }, [start, filter, sortOrder])
  useEffect(() => {
    if (projects.length) {
      startPreload(projects.map(project => project['thumb']))
    }
  }, [projects])
  return <div className={`projectList${start? ' loading': ''}`} ref={setFilterElment}>
    <div className="seperator"></div>
    <div className="filter">
      <div className="sortOrder">Selected Works <img src="./media/Scroll_Down.svg" /></div>
      {/* <Link className="sortOrder"
        to={`${routes.landing}?sort=${nextSortOrder}${filter?`&filter=${filter}`: ''}`}
        onClick={() => false}
      >
        Recent ({sortOrder === 'asc'? '⭣': '⭡'})
      </Link> */}
      <div className="filterCategories">
        <Link className={`filterItem${filter==='digital'?' active': ''}`}
          // to={`${routes.landing}?sort=${sortOrder}${filter!=='digital'?`&filter=digital`: ''}`}
          to="#"
          onClick={(e)=>{e.preventDefault(); return false; isAnimated.current = true}}
        >
          Digital
        </Link>
        <Link className={`filterItem${filter==='physical'?' active': ''}`}
          // to={`${routes.landing}?sort=${sortOrder}${filter!=='physical'?`&filter=physical`: ''}`}
          to="#"
          onClick={(e)=>{e.preventDefault(); return false; isAnimated.current = true}}
        >
          Physical
        </Link>
        <Link className={`filterItem${filter==='brand'?' active': ''}`}
          // to={`${routes.landing}?sort=${sortOrder}${filter!=='brand'?`&filter=brand`: ''}`}
          to="#"
          onClick={(e)=>{e.preventDefault(); return false; isAnimated.current = true}}
        >
          Brand
        </Link>
      </div>
      {/* <select className="mobileFilterCategories" 
        value={filter || ""} 
        onChange={(e) => {
          isAnimated.current = true;
          const newSearchParams = {
            sort: sortOrder
          };
          if (e.currentTarget.value) {
            newSearchParams['filter'] = e.currentTarget.value;
          }
          setSearchParams(newSearchParams);
        }}
      >
        <option value="">All</option>
        <option value="digital">Digital</option>
        <option value="physical">Physical</option>
        <option value="brand">Brand</option>
      </select> */}
    </div>
    <div className="projectsWrapper" ref={setCurrentList}>
      {projects.map((project, idx) => {
        return <div key={idx} className={`projectItem${project['featured']?' featured': ''}`}>
        <Link 
          to={generatePath(routes.project, {
            project: project['slug']
          })}
        >
          <div className="projectThumb" style={project['projectColor']? {backgroundColor:project['projectColor']}: {}}>
            {project['coverVimeo'] ? 
              <iframe src={`https://player.vimeo.com/video/${project['coverVimeo']}?background=1&autoplay=1&loop=1&autopause=0`} frameBorder="0" allow="autoplay" />: 
              <img src={project['thumb']} />
            }
          </div>
        </Link>
        <Link to={generatePath(routes.project, {
            project: project['slug']
          })}
          className="projectInfo"
        >
          <div className="projectName" dangerouslySetInnerHTML={{
            __html: project['name']}}
          />
          <div className="projectCategory">{project['categoryDisplay']}</div>
        </Link>
      </div>
      })}
    </div>
  </div>
}

export default ProjectList;