export const SET_PROJECTS = 'SET_PROJECTS';
export const SET_ABOUT = 'SET_ABOUT';

export default (state, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: action.payload.projects
      };
    case SET_ABOUT:
      return {
        ...state,
        about: action.payload.about
      };
    default:
      return state;
  }
};