import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import jsonContext from "../contexts/json/jsonContext";
import preloadContext from "../contexts/preload/preloadContext";
import routes from "../global/routes";

import "./about.css";

const About = () => {
  const {start, startPreload} = useContext(preloadContext);
  const {about} = useContext(jsonContext);

  useEffect(() => {
    if (about) {
      startPreload([about["photo"]]);
    }
  }, [about])
  return about && <div id="about" className={start? 'loading': ''}>
    {about["photo"] && <div className="aboutPhoto">
      <img src={about["photo"]} />
    </div>}
    {about["about"] && <div className="aboutText">
      {about["about"].map((aboutItem, idx) => {
        return <div key={idx} className="aboutSection">
          {aboutItem["main"] && <div className="mainContent" dangerouslySetInnerHTML={{
            __html: aboutItem["main"]
          }} />}
          {aboutItem["brief"] && <div className="subContent" dangerouslySetInnerHTML={{
            __html: aboutItem["brief"]
          }} />}
          <div className="additionCols">
            {aboutItem["details"].map((detailItem, jdx) => {
              return <div key={jdx} className={`${jdx? "col" :"colMain"}`}>
                {detailItem["header"] && <div className="colHeader" dangerouslySetInnerHTML={{
                  __html: detailItem["header"]
                }} />}
                {detailItem["items"]?.map((item, kdx, subColItems) => {
                  return Array.isArray(item)? <div key={kdx} className="subCol">
                    {item.map((subItem, ldx) => {
                      return <div key={ldx} className="colItem">
                        {subItem[0] && <div className="title" dangerouslySetInnerHTML={{
                          __html: subItem[0]
                        }} />}
                        {subItem[1] && <div className="support" dangerouslySetInnerHTML={{
                          __html: subItem[1]
                        }} />}
                      </div>
                    })}
                    {(kdx === subColItems.length - 1) ? 
                      (detailItem["additionalItem"] &&
                        <div className="colItem last" dangerouslySetInnerHTML={{
                          __html: detailItem["additionalItem"]
                        }} />):
                        null
                    }
                  </div>:
                  <div key={kdx} className="colItem" dangerouslySetInnerHTML={{
                    __html: item
                  }} />;
                })}
              </div>;
            })}
          </div>
        </div>;
      })}
    </div>}
    {about["sideProject"] && <div className="sideProject">
      <div className="header">Side Projects</div>
      <div className="projectDetails" dangerouslySetInnerHTML={{
        __html: about['sideProject']
      }} />
    </div>}
    <Link className="worksLink" to={routes.landing}>
      <img src="./media/Back.svg" /><br/>
      See my Work
    </Link>
  </div>
}

export default About;