import React, { useReducer, useCallback, useEffect, useState } from 'react';
import preloadImages from '../../components/preloadImages';

import PreloadContext from './preloadContext';
import PreloadReducer, { SET_LOADED_PERCENT, FIRST_LOAD_ENDED, START_PRELOAD} from './preloadReducer';

const initialState = {
  images: [],
  start: true,
  loadedPercent: 0,
  firstLoaded: false
};

const PreloadState = ({ children }) => {
  const [state, dispatch] = useReducer(PreloadReducer, initialState);
  // useEffect(() => {
  //   (async () => {
  //     const preloadData = await axios.get(preloadsDataUrl);
  //     dispatch({
  //       type: SET_LOADED_PERCENT,
  //       payload: {
  //         preloadData: formatData(preloadData.data)
  //       }
  //     });
  //   })();
  // }, []);
  const startPreload = (images) => {
    dispatch({
      type: START_PRELOAD,
      payload: {
        start: true
      }
    })
    preloadImages(images, (progress) => {
      const percent = progress * 100;
      dispatch({
        type: SET_LOADED_PERCENT,
        payload: {
          loadedPercent: Math.floor(percent)
        }
      })
    }).then(() => {
      dispatch({
        type: START_PRELOAD,
        payload: {
          start: false
        }
      })
      dispatch({
        type: FIRST_LOAD_ENDED
      })
    })
  };
  const addPreloadImages = (images) => {
    // dispatch({
    //   type: ADD_IMAGE_PRELOAD,
    //   payload: {
    //     images: Array.isArray(images)? images: [images]
    //   }
    // });
    setImages(prevImages => {
      const imagesInArray = Array.isArray(images)? images: [images];
      return [
        ...prevImages, 
        ...imagesInArray
      ];
    })
  }
  return <PreloadContext.Provider
    value={{
      ...state,
      addPreloadImages,
      startPreload,
    }}
  >
    {children}
  </PreloadContext.Provider>
}

export default PreloadState;