import React, { useState } from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
import {BrowserRouter, HashRouter} from 'react-router-dom';
import './index.css';
import App from './app';
import PreloadState from './contexts/preload/preloadState';
import JsonState from './contexts/json/jsonState';

// const Test = () => {
//   const [val, setVal] = useState('');
//   return <input value={val} onChange={(e) => setVal(e.currentTarget.value)} />;
// }
// // import * as eruda from 'eruda';
// ReactDOM.render((
//   <Test />
// ), document.getElementById('root'));
const root = createRoot(document.getElementById('root'));
root.render((
  <JsonState>
    <PreloadState>
      <BrowserRouter basename="/">
      {/* <BrowserRouter basename="/"> */}
      {/* <HashRouter basename="/"> */}
        {/* use HashRouter to enable page changing in pwa */}
        <App />
      {/* </HashRouter> */}
      </BrowserRouter>
    </PreloadState>
  </JsonState>
));

// eruda.init();
