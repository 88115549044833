const preloadImage = src => 
  new Promise(r => {
    const image = new Image()
    image.onload = r
    image.onerror = r
    image.src = src
  })

const preloadImages = (imagesArray, onProgress) => {
  let loaded = 0;
  onProgress(loaded);
  const loadingArray = imagesArray.map(preloadImage);
  for (const loadingPromise of loadingArray) {
    loadingPromise.then(()=> {    
      loaded++;
      onProgress( loaded / imagesArray.length );
    });
  }
  return Promise.all(loadingArray);
}

export default preloadImages;